import Head from 'next/head'
import styles from '../styles/Home.module.css'
import Layout from '../components/Layout'
import { ReactNode } from 'react'
import Footer from '@/components/layout/Footer'
import BannersCarousel from '@/components/carousels/BannersCarousel'
import LogosCarousel from '@/components/carousels/LogosCarousel'
import UpdatePage from '@/components/mobile/update_page'
import { useEffect } from 'react'
import supabase from '@/lib/supabaseClient'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { BUSINESS_USER_ROLE, MOBILE_WIDTH_LIMIT } from '@/lib/constants'
import { useMediaQuery } from '@mui/material'
import MainMobileCarousel from '@/components/carousels/MainMobileCarousel'
import TextBannerCarousel from '@/components/carousels/TextBannerCarousel'
import { GA_TRACKING_ID, GTM_TRACKING_ID } from '@/lib/gtag'
import { getUserRole } from '@/lib/supabaseApi'
import SnowArt from '@/components/art/snow'

const Home = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(`(max-width:${MOBILE_WIDTH_LIMIT}px)`)
  const user = supabase.auth.user()
  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event: any) => {
      if (event == 'PASSWORD_RECOVERY') {
        router.push('/reset_password')
      }
    })
  }, [])

  useEffect(() => {
    getUserRole(user?.email).then((data) => {
      if (data?.role_id === BUSINESS_USER_ROLE) {
        router.push('/business')
      }
    })
  },[])

  const checkStatistics = () => {
    const cookies = document.cookie.split(';')
    const cookieToCheck = cookies.find((cookie) => cookie.includes('statistics'))

    if (cookieToCheck && cookieToCheck.split('=')[1] === 'true') return true
    return false
  }

  return (
    <div className={styles.container}>
      <TextBannerCarousel isMobile={isMobile} />
      <Head>
        <title>{t('pages.home.title')}</title>
        <meta name="description" content={t('seo.meta_desc.home')} />

        {/* GOOGLE TAG MANAGER */}
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-${GTM_TRACKING_ID}');`,
          }}
        />

        {/* GOOGLE ANALYTICS */}
        {checkStatistics() && <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />}
        {checkStatistics() && <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag() { dataLayer.push(arguments); }
              gtag('js', new Date());
              gtag('config', '${GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
            `,
          }}
        />}

      </Head>
      <main
        className={styles.main}
        style={{ minHeight: 'calc(100vh - 380px)', width: '100%' }}
      >
        <SnowArt />
        {isMobile ? (
          <>
            <MainMobileCarousel />
            <UpdatePage />
          </>
        ) : (
          // calc(-60px + 100%)
          <div className={styles.carrousel_banner}>
            <BannersCarousel />
          </div>
        )}
        <br />

        <LogosCarousel />
      </main>
      <br />
      <div className={styles.carrousel_banner}>
        <Footer />
      </div>
    </div>
  )
}

export default Home

Home.getLayout = function getLayout(page: ReactNode) {
  return <Layout>{page}</Layout>
}
